.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 35px;
    padding: 4px 8px;
    border-radius: 35px;
    cursor: pointer;
  }
  
  .IsLight {
    background-color: var(--color-toggle-dark);
  }
  
  .IsDark {
    background-color: inherit;
  }
  
  .container input {
    display: none;
  }
  
  .container input + div {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    position: relative;
    box-shadow: inset 8px -8px 0 0 var(--color-toggle-dark, #ffd902);
    transform: scale(1) rotate(-2deg);
    transition: box-shadow 0.5s ease 0s, transform 0.4s ease 0.1s;
  }
  
  .container input + div::before {
    content: "";
    width: inherit;
    height: inherit;
    border-radius: inherit;
    position: absolute;
    left: 0;
    top: 0;
    transition: background 0.3s ease;
  }
  
  .container input + div::after {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin: -2px 0 0 -2px;
    position: absolute;
    top: 50%;
    left: 50%;
    box-shadow: 0 -16px 0 var(--color-toggle-light, #ffd902),
      0 16px 0 var(--color-toggle-light, #ffd902),
      16px 0 0 var(--color-toggle-light, #ffd902),
      -16px 0 0 var(--color-toggle-light, #ffd902),
      12px 12px 0 var(--color-toggle-light, #ffd902),
      -12px 12px 0 var(--color-toggle-light, #ffd902),
      12px -12px 0 var(--color-toggle-light, #ffd902),
      -12px -12px 0 var(--color-toggle-light, #ffd902);
    transform: scale(0);
    transition: all 0.3s ease;
  }
  
  .container input:checked + div {
    box-shadow: inset 32px -32px 0 0 #ffd902;
    transform: scale(0.5) rotate(0deg);
    transition: transform 0.3s ease 0.1s, box-shadow 0.2s ease 0s;
  }
  
  .container input:checked + div::before {
    background: var(--color-toggle-light, #ffd902);
    transition: background 0.3s ease 0.1s;
  }
  
  .container input:checked + div::after {
    transform: scale(1.5);
    transition: transform 0.5s ease 0.15s;
  }
  