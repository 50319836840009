body::-webkit-scrollbar {
  width: 0px !important;
  /* height: 2px !important; */
}

body::-webkit-scrollbar-thumb {
  background-color: #888 !important;
  border-radius: 20px !important;
}

/* body::-webkit-scrollbar-track {
  background-color: #f2f2f2 !important;
} */